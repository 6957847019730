import React, { useState } from "react";
import {
	Grid,
	Button,
	Divider,
	Typography,
	Drawer,
	Box,
	TextField
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Title from "../../../Title";
import clsx from "clsx";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import { DropzoneArea } from "material-ui-dropzone";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import CubicCertificateService from "../../../../services/CubicCertificateService";
import apiClient from "../../../../auth/apiClient";

export const addCertificateSchema = Yup.object().shape({
	thumbprint: Yup.string().trim().required("Required"),
	password: Yup.string().trim().required("Required")
});

const defaultCertData = {
	thumbprint: '',
	password: ''
};

const cubicCertificateService = new CubicCertificateService(apiClient);

const AddCertificateDrawer = ({
	drawerOpen, setDrawerOpen, onUploadComplete
}) => {
	const [file, setFile] = useState(null);
	const classes = useStyles();
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting, isDirty, isValid },
	} = useForm({
		defaultValues: {
			thumbprint: '',
			password: ''
		},
		resolver: yupResolver(addCertificateSchema),
		mode: "onChange",
	});

	  const enqueueSnackbar = useEnqueueSnackbar();

	const onClose = () => {
		reset(defaultCertData);
		setDrawerOpen(false);
	}
 
	const handleFileChange = (files) => {
		if (files.length === 0) {
			setFile(null);

		} else {
			setFile(files[0]);
		}
	};

	const encodeFileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const onSubmit = async (data) => {
		if (!file) {
			return;
		}
		const encodedFile = await encodeFileToBase64(file);
		const base64EncodedFile = encodedFile.split(',')[1];
		const certificateData = {
			fileName: file.name,
			thumbPrint: data.thumbprint,
			password: data.password,
			encodedFile: base64EncodedFile,
			extension: file.name.split('.').pop()
		};

		try {
			const response = await cubicCertificateService.CreateCertificates(certificateData);
			if (response.status === 200) {
				reset(defaultCertData);
				onUploadComplete();
			} else {
				enqueueSnackbar("Unable to upload cubic certificates", {
					variant: "error",
					tag: "FailedToUploadCubicCertificate",
				});
			}
		} catch (error) {
			enqueueSnackbar("Unable to upload cubic certificates", {
				variant: "error",
				tag: "FailedToUploadCubicCertificate",
			});
		}
	};

	return (
		<Drawer
			classes={{ paper: classes.addCertificatesDrawer }}
			anchor="right"
			open={drawerOpen}
			onClose={onClose}>
			<Grid container>
				<Grid item xs={12} md={12}>
					<Typography className={clsx([classes.addCertificatesTitle])}>
						<Title>
							Add Certificates
						</Title>
					</Typography>
					<Divider />
				</Grid>
			</Grid>
			<Box className={clsx([classes.addCertificateBox])}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} className={clsx([classes.drawerItems])}>
						<DropzoneArea
							acceptedFiles={[
								".pfx, application/x-pkcs12",
							]}
							data-testid="dropzone"
							filesLimit={1}
							type="file"
							onChange={handleFileChange}
						/>
					</Grid>
					<Grid item xs={12} md={12} className={clsx([classes.drawerItems])}>
						<Controller
							name="thumbprint"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									id="thumbprint"
									label="Thumbprint"
									error={Boolean(errors.thumbprint)}
									helperText={errors.thumbprint?.message}
									variant="outlined"
									fullWidth
									InputProps={{
										inputProps: {
											maxLength: 50,
											"data-testid": "thumbprint"
										}
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12} className={clsx([classes.drawerItems])}>
						<Controller
							name="password"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									id="password"
									label="Password"
									error={Boolean(errors.password)}
									helperText={errors.password?.message}
									variant="outlined"
									fullWidth
									InputProps={{
										inputProps: {
											maxLength: 20,
											"data-testid": "password"
										}
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12} className={clsx([classes.drawerItems])}>
						<Button
							className={clsx("uploadBtn", classes.btnControl)}
							name="upload"
							variant="contained"
							color="primary"
							disabled={
								!isDirty || !isEmpty(errors) || !isValid || isSubmitting || !file
							}
							onClick={handleSubmit(onSubmit)}
						>
							Upload
						</Button>
					</Grid>
				</Grid>
			</Box>
			<Box>
				<Divider />
				<Grid container className={clsx([classes.footerContainer])}>
					<Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
						<Button
							className={clsx("cancelBtn", classes.btnControl)}
							name="cancel"
							variant="contained"
							onClick={onClose}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Drawer>
	);
};

AddCertificateDrawer.defaultProps = {
	drawerOpen: false,
	setDrawerOpen: () => { },
	onUploadComplete: () => {}
};

AddCertificateDrawer.propTypes = {
	drawerOpen: PropTypes.bool,
	setDrawerOpen: PropTypes.func,
	onUploadComplete: PropTypes.func,
};

export default AddCertificateDrawer;
