import React from "react";
import clsx from "clsx";
import { useStyles } from "./AccessHolder.style";
import SquareChip from "../../SquareChip"
import { AUDIT_TYPES } from "../../../constants"; 

const AccessHolderAudit = ({ onDrawer, username, action, date, payload, ...props }) => {
  const classes = useStyles();
  const _payload = JSON.parse(props?.history?.payload) ?? null;

  const getActionLabel = () => {
    switch (action) {
      case "add":
        return "Created";
      case "edit":
        return "Edited";
      case "delete":
        return "Deleted";
      default:
        return action;
    }
  };
  console.log("ACCESS HOLDER", _payload);
  const getAccessHolderName = () => {
    switch (action) {
      case "add":
      case "edit":
        if (_payload?.accessHolder === undefined && _payload?.vehicle !== undefined) {
          return `${_payload?.vehicle?.AssignedAccessHolderName?.First} ${_payload?.vehicle?.AssignedAccessHolderName?.Last}`
        }
        return `${_payload?.accessHolder?.ContactInfo?.FirstName} ${_payload?.accessHolder?.ContactInfo?.LastName}`;
      case "delete":
        return _payload?.accessHolderID;
      default:
        return null;
    }
  };

  const getRemainingUsesChangedText = () => {
    try {
      if (action === "edit") {
        if (_payload?.accessHolder === undefined && _payload?.vehicle !== undefined) {
          metaData = _payload?.vehicle?.Metadata ? JSON.parse(_payload?.vehicle?.Metadata) : null;
          const displayText = Object.entries(metaData)
          .map(([key, value]) => `${key} : ${value}`)
          .join(", ");
          return `${displayText ?? ''}`
        }
        var metaData = _payload?.accessHolder?.MetaData ? JSON.parse(_payload?.accessHolder?.MetaData) : null;
        if ((metaData?.PrevRemainingUses || metaData?.PrevRemainingUses === 0) &&
          _payload?.accessHolder?.RemainingUses !== metaData?.PrevRemainingUses) {
          return `Remaining uses changed to ${_payload?.accessHolder?.RemainingUses ?? 0}`;
        }
      }
    } catch (err) {
      return null;
    }
    return null;
  };

  return (
    <>

      <tr>
        <td data-column="Area" className={clsx([classes.tdc])}><SquareChip size="small" color="primary" text={AUDIT_TYPES[props?.history?.category]}/></td>
        <td data-column="Date" className={clsx([classes.tdc])}>{date}</td>
        <td data-column="User" className={clsx([classes.tdc])}>
          <div>{username}</div>
        </td>
        <td data-column="Action" className={clsx([classes.tdc])}>
          <div>{getActionLabel()}</div>
          <div className={clsx([action, classes.groupName])}>{getAccessHolderName()}</div>
        </td>
        <td data-column="Details" className={clsx([classes.tdc,classes.details])}>
          {getRemainingUsesChangedText()}
        </td>
      </tr>

    </>
  );
};

export default AccessHolderAudit;
