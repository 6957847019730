import React, { useState, useEffect } from 'react';
import { useStyles } from "./styles";
import clsx from "clsx";
import AddCertificateDrawer from './CertificateDrawer/AddCertificateDrawer';
import apiClient from "../../../auth/apiClient";
import CubicCertificateService from "../../../services/CubicCertificateService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import moment from "moment-timezone";
import {
  Button,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Paper,
  FormControlLabel,
  Switch,
  Grid
} from "@material-ui/core";

const cubicCertificateService = new CubicCertificateService(apiClient);

const CubicCertificates = () => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [certificates, setCertificates] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showPasswordRows, setShowPasswordRows] = useState([]);  
  const { textConfirmation } = useConfirmationDialog();  
  const classes = useStyles();
  useEffect(() => {
    getAllCubicCertficates();
  }, []);

  const getAllCubicCertficates = async () => {
    try {
      const result = await cubicCertificateService.GetCubicCertificates(true);
      if (result?.data && Array.isArray(result.data)) {
        setCertificates(result.data);
      } else {
        throw new Error("Invalid data format");
      }
    } catch (err) {
      enqueueSnackbar("Unable to retrieve cubic certificates", {
        variant: "error",
        tag: "FailedToRetrieveCubicCertificate",
      });
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  }

  const deleteCertificatefile = async (id) => {
    try {
      await cubicCertificateService.DeleteCetificate(id);
        getAllCubicCertficates();
      
      } catch (error) {
        enqueueSnackbar("Unable to delete cubic certificates", {
          variant: "error",
          tag: "FailedToDeleteCubicCertificate",
        });
      }
  };

  const deleteCertificate = async (id, fileName) => {
    let continueDelete = await textConfirmation({
      title: `Are you sure you want to delete the Certificate?`,
      message: "Please enter the Certificate Name EXACTLY as shown in the input box below to delete.",
      textConfirmation: fileName,
    });

    if (!continueDelete) return;

    deleteCertificatefile(id);
  };

  const enableDisableCertificate = async (id, active) => {
    try {
      const updatedCertificate = {
        certificateId: id,
        active: active
      };

      await cubicCertificateService.UpdateCertificate(updatedCertificate);
      setCertificates(certificates.map(certificate =>
        certificate.certificateId === id
          ? { ...certificate, active: active } 
          : certificate
      ));    
    } catch (error) {
      enqueueSnackbar("Unable to update cubic certificates", {
        variant: "error",
        tag: "FailedToUpdateCubicCertificate",
      });
    }
  };

  const showHidePassword = (index) => {
    if (showPasswordRows.includes(index)) {
      setShowPasswordRows(showPasswordRows.filter((i) => i !== index));
    } else {
      setShowPasswordRows([...showPasswordRows, index]);
    }
  };

  const updateCertificateTable = () => {
    closeDrawer();
    getAllCubicCertficates();
  };

  return <>
    <Grid container className={clsx(classes.gridContainer)} spacing={2} >

      <Grid item xs={12} sm={12} >
        <Button variant="outlined" onClick={() => setDrawerOpen(true)} className={clsx(classes.addCertificatebtn)}>
          Add Certificate
        </Button>
      </Grid> 
      <Grid container className={clsx(classes.gridTableContainer)}>
        <Grid item xs={12} sm={12} >
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Thumbprint</TableCell>
                  <TableCell align="center">Password</TableCell>
                  <TableCell align="center">Upload Date</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {certificates.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.fileName}</TableCell>
                      <TableCell align="center">{row.thumbPrint}</TableCell>
                      <TableCell align="center" onClick={() => showHidePassword(row.certificateId)}>
                        {showPasswordRows.includes(row.certificateId) ? row.password : row.password.replace(/./g, '*')}
                      </TableCell>
                      <TableCell align="center">{
                        moment.utc(row.uploadedDate)
                          .format("MM/DD/YYYY")
                          .toString()}</TableCell>
                      <TableCell align="center">
                        <div className={clsx(classes.actionButtonContainer)}>
                            <Button variant="outlined" onClick={() => deleteCertificate(row.certificateId,row.fileName)} className={clsx(classes.actionButton)}>
                              Delete
                            </Button>
                            <FormControlLabel
                              labelPlacement="end"
                              data-id="certificatestatus"
                              data-testid="certificatestatus"
                              control={
                                <Switch
                                  name="certificatestatus"
                                  checked={row.active}
                                  data-checked={row.active}
                                  onChange={e => enableDisableCertificate(row.certificateId, e.target.checked)}
                                  color="primary"
                                />
                              }
                            />
                          </div>
                      </TableCell>
                    </TableRow>);
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
    <AddCertificateDrawer
      drawerOpen={drawerOpen} setDrawerOpen={closeDrawer} onUploadComplete={updateCertificateTable}
    />
  </>
};

export default CubicCertificates;
