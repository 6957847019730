/* eslint-disable no-mixed-spaces-and-tabs */
import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  addCertificatebtn:{
    float: "right"
  },  
  gridContainer: {
    alignContent: "center",
    justifyContent: "center",
      width: "90%"
  },
  gridTableContainer: {
    alignContent: "center",
    justifyContent: "center"
  },
  gridActionButtons: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",                 
    width: "100%", 
  },
  actionButton: {
    minWidth: "100px",
    margin: "0 10px", 
  },
}));