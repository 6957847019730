import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  addCertificatesTitle: {
    margin: theme.spacing(1),
    marginBottom: 0
  },
  drawerItems: {
    padding: theme.spacing(1),
  }, 
  btnControl: {
    margin: theme.spacing(1),
    float: "right"
  },
  footerContainer: {
  },
  addCertificatesDrawer: {
    width: "100%",
    minWidth: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "22vw",
      minWidth: 400,
      flexShrink: 0,
    },
  },
  addCertificateBox: {
    padding: '10px',
    height: "calc(100vh - 100px)",
    overflowY: 'auto',
    overflowX: 'hidden'
  },  
}));
