import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { TRANSIENT_TYPES } from "../../constants";
import { CREDENTIAL_SUBTYPES } from "../../constants";
import { useStyles } from "./styles";
import {
  Tooltip,
} from "@material-ui/core";

const IsClickable = (credentialType, isActive) => {
  return TRANSIENT_TYPES.indexOf(credentialType?.toLowerCase()) === -1 && isActive;
}

export const ActivityTable = ({
  filteredRows,
  handleDrawerOpen,
  showFacility
 }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Table size="small" className={clsx("activity-table")}>
        <TableHead className={clsx("table-head")}>
          <TableRow className={clsx("activity-row")}>
            <TableCell className={clsx("Credential-number")}>
              Credential Number
            </TableCell>
            <TableCell className={clsx("credential-type")}>Type</TableCell>
            <TableCell className={clsx("name")}>Name</TableCell>
            {showFacility && (
              <TableCell className={clsx("facility-name")}>Facility Name</TableCell>
            )}
            <TableCell className={clsx("device-name")}>Device Name</TableCell>
            <TableCell className={clsx("date-time")}>Date/Time</TableCell>
            <TableCell className={clsx("result-status")}>
              Result/Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={clsx("table-body")}>
          {filteredRows.map((row, index) => (
            <TableRow
              hover={IsClickable(row.credentialType, row.isAccessHolderActive)}
              key={index}
              onClick={() => {
                if (
                  row.credentialType &&
                  TRANSIENT_TYPES.indexOf(row.credentialType.toLowerCase()) ===
                    -1
                ) {
                  handleDrawerOpen(
                    row.isAccessHolderActive,
                    row.accessHolderID
                  );
                }
              }}
              className={
                IsClickable(row.credentialType, row.isAccessHolderActive)
                  ? clsx("activity-row", classes.clickableRow)
                  : "activity-row"
              }
              data-credential={row.credentialNumber ?? "Unknown"}
            >
              <TableCell
                className={clsx("Credential-number", classes.CredentialNumber)}
                data-value={
                  row.credentialType?.toLowerCase() === "ticket"
                    ? row.credentialNumber?.slice(
                        row.credentialNumber.length > 12
                          ? row.credentialNumber.length - 12
                          : 0
                      ) ?? "Unknown"
                    : row.credentialNumber ?? "Unknown"
                }
              >
                {row.credentialType?.toLowerCase() === "ticket"
                  ? row.credentialNumber?.slice(row.credentialNumber.length > 12 ? row.credentialNumber.length - 12 : 0) ?? "Unknown"
                  : <Tooltip title={row.credentialNumber ?? "Unknown"} placement="bottom-start">
                    <span>{row.credentialNumber ?? "Unknown"}</span>
                  </Tooltip>
                }
              </TableCell>
              <TableCell
                className={clsx("credential-type")}
                data-value={row.credentialType ?? "Unknown"}
              >
                {row.credential_SubTypeId == CREDENTIAL_SUBTYPES.ONEPASS ? "One Pass" : row.credentialType ?? "Unknown"}
              </TableCell>
              <TableCell
                className={clsx("name")}
                data-value={row.name === " " || !row.name ? "N/A" : row.name}
              >
                {row.name === " " || !row.name ? (
                  <span style={{ color: "#dfdfdf", fontStyle: "italic" }}>
                    N/A
                  </span>
                ) : (
                  row.name
                )}
              </TableCell>
              {showFacility && (
                <TableCell
                  className={clsx("facility-name")}
                  data-value={row.nearestFacilityName}
                >
                  {row.nearestFacilityName}
                </TableCell>
              )}
              <TableCell
                className={clsx("device-name")}
                data-value={row.deviceName}
              >
                {row.deviceName}
              </TableCell>
              <TableCell
                className={clsx([
                  "date-time",
                  "activityDate",
                  classes.activityDate
                ])}
                data-value={row.activityDate}
              >
                {row.activityDate}
              </TableCell>
              <TableCell
                className={clsx([
                  classes.resultStatus,
                  "status",
                  row.highlight
                ])}
                data-value={row.result
                  .toLowerCase()
                  .split(" ")
                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
              >
                {row.result
                  .toLowerCase()
                  .split(" ")
                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

ActivityTable.defaultProps = { fitleredRows: [], handleDrawerOpen: () => {} };
ActivityTable.propTypes = {
  entityData: PropTypes.object,
  handleDrawerOpen: PropTypes.func
};

export default ActivityTable;
